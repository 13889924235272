.nav-container {
    padding: 16px;
    background-color: var(--azi-primary);
}

.nav-white {
    border-radius: 30px;
    box-shadow: 0 3px 20px 0px lightgray;
    position: sticky;
    width: 60%;
    top: 16px;
    left: 0;
    z-index: 3;
    padding: 2px 22px;
    margin: 16px auto;
    background-color: #fff;

    .nav-list-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
    }

    .logo,
    .logo-img {
        flex-grow: unset;
        width: 33px;
        height: 40px;

    }
    .language-go-to-app-button {
        .language {
            display: none;
        }
    }

    .go-to-app-button {
        color: var;
        background: var(--azi-primary);

        &:hover {
            background-color: var(--azi-primary-button-active);
        }
    }

    li {
        a {
            color: var(--azi-primary);
        }
    }
}

nav {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    height: 57px;
    padding: 16px 22px;
    // transition: 0.5s;
    transition: top 0.5s, color 1s, background-color 0.3s;
    background-color: #083a5e;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    width: unset;
    .menu-items {
        display: none;
    }
    .logo,
    .logo-img {
        transition: 0.5s;
        width: 45px;
        height: 53px;
    }

    li {
        font-size: 13px;
        font-weight: 600;
        padding: 0 15px;
        transition: .3s;

        a {
            transition: 0.3s;
            color: #fff;

            &:hover{
                color: var(--azi-highlight-text-light);
            }
        }
    }

    .go-to-app-button {
        display: none;
        color: var(--azi-primary);
        background: #fff;
    }

    .menu-icon {
        display: block;
        width: 28.7px;
        height: 37.18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .language-go-to-app-button {
        .language {
            display: none;
        }

        .buttons-container {
            display: none;
        }
    }
}

.language {
    display: none;
}

.hide-mobile-menu {
    animation-name: lang-slide-out-mobile;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
}
.disp-none {
    display: none;
}

.menu-mobile {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 30px;
    box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    .close-menu-mobile-icon {
        display: flex;
        justify-content: flex-end;
        padding: 30px 21px 16px 21px;
        div {
            background: url(../../../public/icons/shared/close-icon.svg)
                no-repeat center center;
            height: 27.98px;
            width: 27.98px;
        }
    }

    .menu-mobile-items {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-inline-start: 0;

        li {
            font-weight: 600;
            font-size: 20px;
            margin-top: 14.49px;
            width: 80%;
            color: #083a5e;
            text-align: center;

            .strip {
                background-color: rgb(219, 219, 219);
                margin-top: 15.5px;
            }
        }
    }
    .menu-mobile-language-button {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .language {
            display: flex;
            color: var(--azi-primary);
            width: 150px;
            padding: 9.5px 15px;

            font-size: 16px;
            border-radius: 30px;
            border: 2px solid var(--azi-primary);
            justify-content: center;
            align-items: center;

            .language-container {
                .language-flag {
                    display: none;
                }
            }
        }
    }
    .menu-mobile-button-container {
        padding-top: 30px;
        //pointer-events: none;
    }
    .alert-collapse {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0px auto 0px auto;
        width: 80%;
        .alert-container {
            margin-top:30px;
            border: 2px solid var(--azi-primary) !important;
            border-radius: 20px;
            .alert-title {
                font-weight: 600;
                font-size: 15px;
            }
            .highlight-text {
                color: var(--azi-highlight-text-light);
            }
        }
    }
    .alert-button-container {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        .azi-button {
            width: 20%;
            display: flex;
            justify-content: center !important;
            align-items: center !important;
        }
    }
}

.scroll-top-button {
    display: none;
}

@media (min-width: 768px) {

    .nav-white{
        .logo, .logo-img{
            width: 41px;
            height: 40px;
        }
        .go-to-app-button{
            border: none;

            &:hover{
                color: white;
            }
        }
    }
    nav {
        .logo {
            flex-grow: 1;
            flex-basis: 0;
        }

        ul {
            padding-inline-start: 0px;
        }

        .language-go-to-app-button {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            column-gap: 30%;
            justify-content: flex-end;

            .language {
                opacity: 1;
                display: flex;
                flex-direction: column;
                color: white;
                justify-content: center;
                font-size: 13px;
                font-weight: 600;
                position: relative;
                align-items: center;
                border: 2px solid #fff;
                justify-content: center;
                padding: 9.5px 11.5px;
                border-radius: 30px;

                &:hover {
                    cursor: pointer;
                }

                .language-container {
                    display: flex;

                    align-items: center;

                    .language-text {
                        margin-right: 4px;
                    }

                    .language-flag {
                        width: 17px;
                        height: 15px;
                    }
                }

                .language-dropdown-hidden {
                    animation-name: lang-slide-out;
                    animation-fill-mode: forwards;
                }

                .disp-none {
                    display: none;
                }

                .language-dropdown-show {
                    animation-name: lang-slide-in;
                    animation-fill-mode: forwards;
                }

                .language-dropdown-container {
                    animation-duration: 0.6s;
                    box-shadow: var(--azi-element-shadow);

                    border-radius: 15px;
                    z-index: 200;
                    position: absolute;
                    //left: -50%;
                    top: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #fff;
                    color: var(--azi-primary);

                    .language-element {
                        width: calc(100% - 30px);
                        padding: 10px 15px;
                        border-bottom: 1px solid var(--azi-primary);
                        display: flex;
                        justify-content: center;
                        transition: 0.3s;
                        cursor: pointer;

                        &:nth-of-type(5) {
                            border-bottom: none;
                        }

                        img {
                            margin-left: 4px;
                        }

                        &:nth-of-type(1) {
                            border-radius: 15px 15px 0 0;
                        }

                        &:last-child {
                            border-radius: 0 0 15px 15px;
                        }

                        &:hover {
                            background-color: var(--azi-white-button-active);
                        }
                    }
                }
            }
        }
        .menu-items {
            display: block;
            display: flex;
            flex-direction: row;
        }

        .go-to-app-button {
            color: white;
            background-color: var(--azi-priamry);
            border: 2px solid white;

        }

        .is-active {
            color: var(--azi-secondary);
        }
        .menu-icon {
            display: none;
        }
        // .logo,
        // .logo-img {
        // }
    }
    .scroll-top-button {
        padding: 10px 4px;
        .arrow-up {
            width: 20px;
            //transform: rotate(180deg);
        }
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 50px;
        //border: 2px solid var(--azi-primary);
        border-radius: 20px;
        position: fixed;
        z-index: 10;
        bottom: -250px;
        right: 40px;
        box-shadow: var(--azi-element-shadow);
        transition: 0.5s;
        &:hover {
            background-color: var(--azi-white-button-active);
        }
    }
}

@media (min-width: 992px) {
    nav {
        .logo, .logo-img{
            width: 63px;
            height: 53px;
        }
        .language-go-to-app-button {
            .buttons-container {
                display: flex;
                column-gap: 15px;
            }
        }

        .go-to-app-button {
            display: flex;
            pointer-events: auto;
            &:hover {
                background-color: var(--azi-white-button-active);
                color: var(--azi-primary);
            }
        }
    }
}
.active-route{
    color:var(--azi-highlight-text-light) !important;
}

@media (min-width: 1540px) {
    .nav-white {
        .logo {
            flex-grow: 1;
            .logo-img {
            }
        }
        .nav-list-container {
            flex-grow: unset;
            display: flex;
            justify-content: flex-end;
        }
    }
}

@keyframes lang-slide-in {
    0% {
        opacity: 0;
        transform: translate(0, -50%) scale(0.5, 0);
    }
    100% {
        opacity: 1;
    }
}

@keyframes lang-slide-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate(0, -50%) scale(0.5, 0);
    }
}

@keyframes lang-slide-in-mobile {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: translate(0, -100%) scale(0.5, 0);
    }
    100% {
        opacity: 1;
    }
}

@keyframes lang-slide-out-mobile {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
        transform-origin: top center;
        transform: translate(0, -100%) //scale(0.5, 0);
    }
}

@keyframes moveUp {
    0% {
        top: 0;
    }

    100% {
        top: -89px;
    }
}

@keyframes moveDown {
    0% {
        top: -89px;
    }

    100% {
        top: 0;
    }
}
