.azi-contact-form-section {
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;

    .azi-contact-form-container {
        .desc {
            color: var(--azi-primary);
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            img {
                display: none;
            }
        }
        .azi-contact-form {
            margin: 50px 0 0 0;
            width: 100%;
            .alert-collapse {
                margin-top: 30px;

                .alert-container {
                    border: 2px solid var(--azi-primary);
                    border-radius: 20px;

                    .alert-title {
                        font-weight: 600;
                        font-size: 15px;
                    }
                    .highlight-text {
                        color: var(--azi-highlight-text-light);
                    }
                }
            }

            .alert-button-container {
                margin-top: 15px;
                display: flex;
                justify-content: flex-end;
                .azi-button {
                    width: 20%;
                    display: flex;
                    justify-content: center !important;
                    align-items: center !important;
                }
            }
            .azi-contact-form-elements {
                .input-container {
                    display: flex;
                    flex-direction: column;
                }
                display: flex;
                flex-direction: column;

                div input {
                    padding: 8px 24px;
                    font-size: 14px;
                    border: 2px solid var(--azi-primary);
                    color: var(--azi-primary);
                    outline: none;
                    border-radius: 20px;
                    margin-bottom: 28px;

                    &::placeholder {
                        color: var(--azi-primary);
                    }
                    &:focus {
                        border-color: var(--azi-secondary);
                    }
                }

                div textarea {
                    padding: 8px 24px;
                    font-family: "Inter", sans-serif;
                    font-size: 14px;
                    min-height: 182px;
                    outline: none;
                    border: 2px solid var(--azi-primary);
                    color: var(--azi-primary);
                    border-radius: 20px;
                    margin-bottom: 28px;
                    resize: none;
                    max-height: 400px;

                    &::placeholder {
                        color: var(--azi-primary);
                    }

                    &:focus {
                        border-color: var(--azi-secondary);
                    }
                }

                button {
                    margin: 0 0 0 auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    padding: 11.5px 15px;
                    border-radius: 52px;
                    border: none;
                    font-weight: 600;
                    font-size: 10px;
                    background: var(--azi-primary);
                    color: white;
                    width: 104px;
                    height: 34px;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    outline: none;
                    transition: transform 0.125s;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: var(--azi-primary-button-active);
                    }

                    &:active {
                        transform: scale(0.9, 0.9);
                    }

                    &.loading {
                        animation: button-loading 0.5s forwards;
                    }

                    &.ready {
                        text-indent: 0;
                        color: transparent;
                        background-color: var(--azi-primary);
                        animation: button-ready 0.333s forwards;
                    }

                    &.fail {
                        text-indent: 0;
                        color: transparent;
                        background-color: var(--azi-primary);
                        animation: button-fail 0.333s forwards;
                    }

                    &.ready:before {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: auto;
                        margin: 0;
                        width: auto;
                        height: auto;
                        border-radius: 0;
                        background-color: transparent;
                        border-color: #8cce1e;
                        color: #8cce1e;
                        content: "✔";
                        opacity: 0;
                        z-index: 2;
                        animation: button-ready-label 0.5s forwards 0.275s;
                    }

                    &.ready:after {
                        opacity: 1;
                        animation: button-dot-outro 0.333s;
                    }

                    &.fail:before {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: auto;
                        margin: 0;
                        width: auto;
                        height: auto;
                        border-radius: 0;
                        background-color: transparent;
                        border-color: #ce1e1e;
                        color: #ce1e1e;
                        content: "❌";
                        opacity: 0;
                        z-index: 2;
                        animation: button-ready-label 0.5s forwards 0.275s;
                    }

                    &.fail:after {
                        opacity: 1;
                        animation: button-dot-outro 0.333s;
                    }
                }
            }
        }
    }
}

@media (min-width: 760px) {
    .azi-contact-form-section {
        padding-top: 0;
    }
}

@media (min-width: 992px) {
    .azi-contact-form-section {
        margin: 0;
        .azi-contact-form-container {
            display: flex;
            align-items: flex-start;
            .desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 80px;
                padding-right: 50px;
                text-align: left;
                img {
                    display: block;
                    width: 200px;
                }
            }
            .azi-contact-form {
                margin: 0;
                width: 80%;
                .alert-collapse {
                    width:100%;
                    .alert-container {
                        //width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .azi-contact-form-elements {
                    display: flex;
                    flex-direction: column;
                    //max-width: 450px;
                    .azi-button {
                        margin: 0 0 0 auto;
                        width: 20%;
                    }
                }
            }
        }
    }
}

.disp-none{
    display: none;
}

@keyframes button-loading {
    0% {
    }

    20% {
        color: transparent;
        transform: scale(1, 1);
    }

    40% {
        border-color: var(--azi-primary);
        background-color: transparent;
        transform: scale(1, 1);
    }

    60% {
        transform: scale(0.7, 0.7);
        width: 1.5rem;
        text-indent: -0.6125rem;

        color: transparent;
        border-color: var(--azi-primary);
        background-color: var(--azi-primary);
    }

    80% {
        transform: scale(1, 1);
    }

    100% {
        width: 2.5rem;

        background-color: var(--azi-primary);
        border-color: var(--azi-primary);
        color: transparent;
    }
}

@keyframes button-dot-intro {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 1;
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes button-dot-pulse {
    0% {
        border: none;
        opacity: 1;
        transform: scale(0.65, 0.65);
    }

    15% {
        border: none;
        transform: translate(0.75, 0.75);
    }

    45% {
        border: none;
        transform: translate(0.65, 0.65);
    }

    55% {
        border: none;
        transform: translate(0.85, 0.85);
    }

    85% {
        border: none;
        transform: translate(0.75, 0.75);
    }

    100% {
        border: none;
        opacity: 1;
        transform: translate(0.65, 0.65);
    }
}

@keyframes button-ready {
    0% {
    }

    10% {
        background-color: var(--azi-primary);
        border-color: #8cce1e;
    }

    70% {
        background-color: #fff;
        transform: scale(1.1, 1.1);
    }

    100% {
        font-weight: bold;
        border: 2px solid #8cce1e;
        background-color: #fff;
    }
}

@keyframes button-dot-outro {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1, 1);
    }
}

@keyframes button-ready-label {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes button-fail {
    0% {
    }

    10% {
        background-color: var(--azi-primary);
        border-color: #ce1e1e;
    }

    70% {
        background-color: #fff;
        transform: scale(1.1, 1.1);
    }

    100% {
        font-weight: bold;
        border: 2px solid #ce1e1e;
        background-color: #fff;
    }
}
