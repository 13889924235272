.azi-price-list-section {
    overflow: hidden;
    border-radius: 45px 45px 0 0;
    box-shadow: 0px -42px 65px -35px rgb(44 44 44);
    background-color: var(--azi-background);
    position: relative;
    z-index: 2;

    .azi-price-list-container {
        //margin: 50px 29px;

        .azi-price-list-basic-explanation {
            display: flex;
            flex-direction: column;
            .explanation-photo {
                display: none;
            }
            .explanation-text-container {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                color: var(--azi-primary);
                .explanation-header {
                    text-align: center;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 21px;
                }
                .explanation-desc {
                    text-align: center;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }

        .azi-price-list-table {
            margin-top: 90px;
            height: 450px;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(8, 1fr);
            .col-1,
            .col-2 {
                border-radius: 25px;
                padding-top: 5px;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                color: var(--azi-primary);
            }
            .col-1 {
                grid-column: 3 / span 2;
                grid-row: 1 / span 2;
            }

            .col-2 {
                grid-column: 5 / span 2;
                grid-row: 1 / span 1;
            }

            .col-3{
                grid-column: 5 / span 2;
                grid-row: 8 / span 1;
                display: flex;
                justify-content: center;
            }

            .free-text {
                display: flex;
                align-items: flex-end;
                color: var(--azi-secondary);
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                p {
                    margin: 0;
                }
            }

            .line-break {
                grid-column: span 2 / -1;
                grid-row: 1 / span 8;
                border-left: 1px solid lightgray;
                z-index: 1;
                pointer-events: none;
            }

            .row-1,
            .row-2,
            .row-3,
            .row-4,
            .row-5,
            .row-6 {
                border-radius: 25px;
                display: flex;
                margin: 5px 0;
                color: var(--azi-primary);
                font-weight: 600;
                font-size: 13px;
                padding: 0 9px 0px 20px;
                line-height: 16px;
                background: var(--azi-element-background);

                .value-1,
                .value-2,
                .value-3 {
                    width: calc(100% / 3);
                    display: flex;
                    align-items: center;
                }

                .value-1 {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 5px 0;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    .value-1-2 {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
                .value-2,
                .value-3 {
                    justify-content: center;
                }
            }
            .row-1 {
                grid-column: 1 / span 6;
                grid-row: 2 / span 1;
                border: 3px dashed var(--azi-secondary);
            }

            .row-2 {
                grid-column: 1 / span 6;
                grid-row: 3 / span 1;
            }
            .row-3 {
                grid-column: 1 / span 6;
                grid-row: 4 / span 1;
            }
            .row-4 {
                grid-column: 1 / span 6;
                grid-row: 5 / span 1;
            }
            .row-5 {
                grid-column: 1 / span 6;
                grid-row: 6 / span 1;
            }
            .row-6 {
                grid-column: 1 / span 6;
                grid-row: 7 / span 1;
            }
        }
    }
}
@media (min-width: 760px) {
    .azi-price-list-section {
    }
}

@media (min-width: 992px) {
    .bgc-section {
        background-color: var(--azi-background);
    }
    .azi-price-list-section {
        border-radius: 0;
        box-shadow: none;
        .azi-price-list-container {
            .azi-price-list-basic-explanation {
                flex-direction: row;
                .explanation-photo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    picture{
                        max-width: 29%;
                        max-height: 81%;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .explanation-text-container {
                    width: 50%;
                    .explanation-header {
                        text-align: left;
                    }
                    .explanation-desc {
                        text-align: left;
                    }
                }
            }

            .azi-price-list-table {
                height: 247px;
                display: grid;
                grid-template-columns: repeat(15, 1fr);
                grid-template-rows: repeat(4, 1fr);
                .col-1,
                .col-2 {
                    margin-top: 10px;
                    padding: 12px 12px 12px 20px;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    background-color: var(--azi-element-background);
                }
                .col-1 {
                    grid-column: 1 / span 15;
                    grid-row: 2 / span 1;
                }
                .col-2 {
                    grid-column: 1 / span 15;
                    grid-row: 3 / span 1;
                }
                .col-3 {
                    grid-column: 1 / span 2;
                    grid-row: 4 / span 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .free-text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    grid-column: 4 / span 2;
                    grid-row: 4 / span 1;
                }
                .line-break {
                    display: none;
                }
                .row-1,
                .row-2,
                .row-3,
                .row-4,
                .row-5,
                .row-6 {
                    border-radius: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 0;
                    padding: 0 0;
                    background-color: transparent;
                    border-right: 1px solid lightgrey;

                    .value-1,
                    .value-2,
                    .value-3 {
                        width: 100%;
                        display: flex;
                        align-items: center;
                    }
                    .value-2,
                    .value-3 {
                        margin-top: 42px;
                    }

                    .value-3 {
                        margin-top: 45px;
                    }
                }
                .row-1 {
                    grid-column: 4 / span 2;
                    grid-row: 1 / span 4;
                    border: 3px dashed var(--azi-secondary);
                    border-radius: 25px;
                    .value-2 {
                        margin-top: 61px;
                    }

                    .value-3 {
                        margin-top: 45px;
                    }
                }
                .row-2 {
                    grid-column: 6 / span 2;
                    grid-row: 1 / span 4;
                }
                .row-3 {
                    grid-column: 8 / span 2;
                    grid-row: 1 / span 4;
                }
                .row-4 {
                    grid-column: 10 / span 2;
                    grid-row: 1 / span 4;
                }
                .row-5 {
                    grid-column: 12 / span 2;
                    grid-row: 1 / span 14;
                }
                .row-6 {
                    grid-column: 14 / span 2;
                    grid-row: 1 / span 4;
                    border-right: none;
                }
            }
        }
    }
}

#menu-button {
    padding: 5px 12px 5px 16px;
    border-radius: 50px;
    border: solid 1px var(--azi-primary);
    margin: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--azi-primary);
    font-size: 12px;
    width: 60px;
    .menu-button-icon {
        margin-left: 6px;
        transition: 0.5s;
    }
}
.currency-menu-item {
    color: var(--azi-primary) !important;
    width: 90px;
    display: flex !important;
    justify-content: space-between !important;
}
.currency-menu-item-selected{
    background-color: var(--azi-white-button-active) !important;
}
