.azi-benefits-section {
    background: linear-gradient(
        0deg,
        rgba(250, 251, 252, 1) 0%,
        rgb(226, 226, 226) 53%,
        rgba(250, 251, 252, 1) 100%
    );
    z-index: 2;
    position: relative;
    overflow: hidden;
    .azi-benefits-element {
        &:nth-of-type(4) {
            margin-bottom: 0px;
        }
        margin-bottom: 90px;
        display: grid;
        grid-template-columns: 29px repeat(6, 1fr) 29px;
        grid-template-rows: repeat(4, 1fr);
        &:nth-of-type(4) {
            grid-template-rows: repeat(3, 1fr);
        }
        .azi-benefits-element-image {
            display: flex;
            justify-content: center;
            grid-column: 3 / span 4;
            grid-row: 1 / span 4;
            picture {
                max-width: 75%;
                max-height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .ml-img {
                max-width: 100%;
            }
        }
        .azi-benefits-element-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-column: 2 / span 6;
            grid-row: 6 / span 3;
            color: var(--azi-primary);
            .azi-benefits-element-title {
                font-weight: 700;
                font-size: 20px;
            }
            .azi-benefits-element-description {
                text-align: center;
                margin-top: 20px;
                font-weight: 600;
                font-size: 14px;
                line-height: 161.02%;
            }
        }
    }
}

@media (min-width: 992px) {
    .azi-benefits-section {
        .azi-benefits-header {
            padding: 0 12%;
        }
        .azi-benefits-element {
            margin-bottom: 0;
            grid-template-columns: repeat(14, 1fr);
            grid-template-rows: repeat(4, 1fr);
            .azi-benefits-element-image {
                picture {
                    img {
                        transition: 0.5s;
                    }
                    img:hover {
                        transform: scale(1.1);
                    }
                }

                display: flex;
                justify-content: flex-start;
                grid-column: 4 / span 3;
                grid-row: 1 / span 3;
            }
            .azi-benefits-element-text {
                align-items: flex-start;
                grid-column: 9 / span 3;
                grid-row: 1 / span 3;
                .azi-benefits-element-description {
                    text-align: start;
                }
            }
        }
        .azi-benefits-element-right {
            .azi-benefits-element-image {
                justify-content: flex-end;
                grid-column: 9 / span 3;
                grid-row: 1 / span 3;
            }
            .azi-benefits-element-text {
                align-items: flex-start;
                grid-column: 4 / span 3;
                grid-row: 1 / span 3;
                .azi-benefits-element-description {
                    text-align: start;
                }
            }
        }
    }
}

@media (min-width: 1540px) {
    .azi-benefits-element {
        grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(4, 1fr);
        .azi-benefits-element-image {
            grid-column: 5 / span 3;
            grid-row: 1 / span 3;
        }
        .azi-benefits-element-text {
            grid-column: 10 / span 3;
            grid-row: 1 / span 3;
        }
    }
    .azi-benefits-element-right {
        .azi-benefits-element-image {
            grid-column: 10 / span 3;
            grid-row: 1 / span 3;
        }
        .azi-benefits-element-text {
            grid-column: 5 / span 3;
            grid-row: 1 / span 3;
        }
    }
}
