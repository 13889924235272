.language-chevron{
    fill:white !important;
    color:white !important;
    display: none;
   
}

.language{
    transition: .3s;;
    &:hover {
        background-color: var(--azi-white-button-active);

        .language-text{
            color: var(--azi-primary);
        }
       
    }
}

@media (min-width: 992px){
    .language-chevron{
        display: block;
    }
}

.current-selected-element{
    background-color: var(--azi-white-button-active) !important
}


