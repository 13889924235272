.specyfic-news-section {
    padding-top: 30%;
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;
    border-radius: 45px 45px 0 0;
    box-shadow: 0px -42px 65px -35px rgb(44 44 44);
    .specyfic-news-section-header {
        margin: 0 29px 0 29px;
        font-size: 40px;
        font-weight: 600;
        color: var(--azi-primary);
    }

    .specyfic-news-date {
        margin: 0 29px 0 29px;
        color: var(--azi-primary);
    }

    .specyfic-news-container {
        margin: 50px 29px 0 29px;
        padding-bottom: 50px;
        background-color: var(--azi-background);

        .specyfic-news-header {
            font-size: 36px;
            font-weight: 500;
            color: var(--azi-primary);
        }

        .specyfic-news-text {
            font-size: 16px;
            color: var(--azi-primary);
            white-space: break-spaces;
        }

        .news-return-button{
            width: 180px;
        }

        .specyfic-news-list {
            margin: 30px 0;
            list-style: circle;
            line-height: 2;
        }

        .specyfic-news-img {
            margin: 30px 0;
            display: flex;
            justify-content: center;
    

            img{
                border-radius: 15px;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

@media (min-width: 760px) {
    .specyfic-news-section {
        padding-top: calc(10% - 40px);
    }
}

@media (min-width: 992px) {
    .specyfic-news-section {
        padding-top: calc(10% - 40px);
        box-shadow: none;
        border-radius: 0;
        margin: 0 10%;
        .specyfic-news-container {
            margin: 50px 29px 0 29px;
        }
    }
}

@media (min-width: 1540px) {
    .specyfic-news-section {
        .specyfic-news-container {
            margin: 50px 29px 0 29px;
        }
    }
}
