.azi-packages-section {
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;
    .azi-packages-container {
        margin: 0 0 20px 0;
        .azi-packages-description {
            color: var(--azi-primary);
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 35px;
        }
        .azi-packages-elements-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 45px;
            .packages-element {
                transition: 0.7s;
                &:hover {
                    .element-photo {
                        img {
                            transform: scale(1.15);
                        }
                    }
                }
                border-radius: 25px;
                position: relative;
                padding: 45px 20px 20px 20px;
                color: var(--azi-primary);
                max-width: 250px;
                height: 200px;
                background: #ffffff;
                box-shadow: 2px 4px 15px 4px rgba(8, 58, 94, 0.2);

                .element-title {
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 34px;
                }
                .element-desc {
                    margin-top: 10px;
                    max-width: 65%;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                }
                .element-open-modal-icon {
                    position: absolute;
                    left: 20px;
                    bottom: 35px;
                    cursor: pointer;
                }
                .element-photo {
                    display: flex;
                    justify-content: flex-end;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    img {
                        transition: 0.5s;
                        max-width: 70%;
                        max-height: 70%;
                    }
                }
            }
        }
    }
}
.modal {
    padding: 39px 30px 30px 30px;
    background-color: #fff;
    max-width: 100%;
    height: 80%;
    color: var(--azi-primary);
    .title {
        font-weight: 600;
        font-size: 38px;
        line-height: 46px;
        text-align: center;
    }
    .modal-container {
        margin-top: 37px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 50px;
        .description {
            z-index: 1;
            background-color: transparent;
            font-weight: 600;
            font-size: 14px;
            line-height: 161.02%;
        }
        .benefits {
            z-index: 1;
            background-color: transparent;
            .modal-benefits-element {
                padding: 0 0 15px 0;

                img {
                    margin: 0 10px 0 0;
                }
            }
        }
        .azi-button {
            margin: 0 auto;
            width: 100px;
        }
    }
    .modal-photo {
        display: none;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        right: 5px;
        bottom: 5px;
        img {
            display: none;
            max-width: 50%;
            max-height: 50%;
        }
    }
}

@media (min-width: 760px) {
    .azi-packages-section {
        .azi-packages-container {
            .azi-packages-elements-container {
                align-items: flex-start;
                row-gap: 35px;
                column-gap: 30px;
            }
        }
    }
    .modal {
        padding: 39px 61px 39px 61px;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .azi-packages-section {
        .azi-packages-container {
            .azi-packages-elements-container {
                margin-top: 65px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }
    .modal {
        position: relative;
        height: unset;
        .modal-photo {
            display: flex;
            img {
                display: block;
                max-width: 80%;
                max-height: 80%;
                -webkit-mask-box-image: radial-gradient(circle, rgba(0, 0, 0, 1) 9%, rgba(255, 255, 255, 0) 100%);
                -webkit-mask-mode: luminance;
                mask-mode: luminance;
            }
        }
        .modal-container {
            .benefits {
                .modal-benefits-element {
                    max-width: 50%;
                }
            }
            .azi-button {
                margin: 0 0;
            }
        }
    }
}

@media (min-width: 1540px) {
    .modal {
        max-width: 80vw;
        max-height: 80vh;
        .modal-photo {
            img {
                max-width: 80%;
                max-height: 80%;
            }
        }
    }
}
