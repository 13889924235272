.not-found-section-container{
    width: 100%;
    height: 80vh;
    position: relative;
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;

.not-found-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--azi-primary);

    h3{
        margin-top: 0;
        text-align: center;
        font-size: 48px;
        width: 100%;
    }

    p{
        text-align: center;
        font-size: 16px;
        margin-bottom: 50px;
    }
}
}