.azi-projects-section {
    //padding-top: 30%;
    //padding-bottom: 50px;
    border-radius: 45px 45px 0 0;
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;
    box-shadow: 0px -42px 65px -35px rgb(44 44 44);

    .azi-projects-container {
        //margin: 50px 29px 0 29px;
        color: var(--azi-primary);
        .project-element {
            .element-title {
                //margin-top: 50px;
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
            }
            .element-description {
                margin: 52px 0 28px 0;
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
            }
            .goal-element-description {
                font-weight: 600;
                font-size: 14px;
                line-height: 161.02%;
            }
            .element-subsidy {
                margin: 28px 0;
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
            }
            .element-photo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 25px;
                img {
                    max-width: 100%;
                    max-width: 100%;
                }
            }
            .element-partner-photo {
                margin-top: 10px;
                display: flex;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-width: 100%;
                }
            }
            .element-break-line {
                margin: 28px 0 29px 0;
                background: var(--azi-primary);
                height: 12px;
                border-radius: 10px;
            }
            .element-footer-description {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
            }
            .element-buttons {
                margin: 38px 0;
                display: flex;
                flex-direction: row;
                column-gap: 15px;
            }
            .element-footer-photo {
                display: none;
            }
            .element-footer-photo-mobile {
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                .row-1, .row-2 {
                    display: flex;
                    justify-content: space-between;
                    img {
                        max-width: 40%;
                        object-fit: contain;
                    }
                }
            }

            &:nth-of-type(1) {
                padding-bottom: 80px;
                border-bottom: 1px solid var(--azi-primary);
            }
        }
    }
}

@media (min-width: 760px) {
    .azi-projects-section {
        .azi-projects-container {
            .project-element {
                .element-footer-photo {
                    display: flex;
                    column-gap: 30px;
                    height: 70px;
                    img {
                        max-width: 15%;
                        object-fit: contain;
                        //height:auto
                    }
                }
                .element-footer-photo-mobile{
                    display: none;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .azi-projects-section {
        border-radius: 0;
        box-shadow: none;
    }
}
