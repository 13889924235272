:root {
    /* colors */
    --azi-primary: #083a5e;
    --azi-secondary: #e97c64;
    --azi-white: #fff;
    --azi-element-background: #eeeeee;

    /* buttons active colors */
    --azi-primary-button-active: #1179c4;
    --azi-white-button-active: #e2eef9;

    /* buttons disabled colors */
    --azi-disabled-button-background: rgb(181, 196, 207);

    /* element shadows */
    --azi-element-shadow: 0px 4px 15px 2px rgba(8, 58, 94, 0.2);

    /* fonts */
    --azi-font-family: Inter, sans-serif;

    --azi-gradient: linear-gradient(79deg, #1179c4 0%, #083a5e 100%, rgba(255, 255, 255, 0) 0%);
    --azi-gradient-reverse: linear-gradient(270deg, #1179c4 0%, #083a5e 100%, rgba(255, 255, 255, 0) 0%);

    --azi-highlight-text: #083a5e;

    --azi-background: #fafbfc;

    --azi-highlight-text-light: #21a3ff;
}

body {
    // background-color: #ECECEC;
    background-color: #fafbfc;
    font-family: "Noto Sans", sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: #083a5e;
}

@for $i from 1 through 64 {
    .spacer-#{$i}px {
        padding-bottom: $i * 1px !important;
        background-color: var(--azi-background);
        z-index: 2;
        position: relative;
    }
}

@for $i from 1 through 100 {
    .width-#{$i} {
        width: $i * 1% !important;
    }
}

.bold-text {
    font-weight: bolder;
}


.azi-button {
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 11.5px 15px;
    border-radius: 52px;
    border: none;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari                           */
    -webkit-user-select: none; /* Safari                               */
    -khtml-user-select: none; /* Konqueror HTML                       */
    -moz-user-select: none; /* Firefox w przeszłości (stare wersje) */
    -ms-user-select: none; /* Internet Explorer (>=10) / Edge      */
    user-select: none;
}

button:disabled{
    background-color: var(--azi-disabled-button-background) !important;
    cursor: default !important;

    &:hover{
        background-color: var(--azi-disabled-button-background) !important;
    }
}

.azi-button-white {
    background: white;
    color: var(--azi-primary);

    &:hover {
        background-color: var(--azi-white-button-active);
    }
}

.azi-button-primary {
    background: var(--azi-primary);
    color: white;
    &:hover {
        background-color: var(--azi-primary-button-active);
    }
}

.azi-button-disabled-white {
    color: var(--azi-primary);
    background: var(--azi-disabled-button-background);
}

.section-spacer {
    padding-top: 20%; //10%
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;
}

.sction-prim-white-spacer {
    padding-top: 6%;
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-justify-content-center {
    justify-content: center;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-align-items-center {
    align-items: center;
}

.flex-align-items-end {
    align-items: flex-end;
}

.bgc-section {
    background-color: var(--azi-primary);
}

//Global MUI styles change
.css-ypiqx9-MuiDialogContent-root {
    padding: 39px 30px 30px 30px !important;
}

.section-header {
    font-size: 40px;
    font-weight: 600;
    color: var(--azi-primary);
    max-width: 100%;
    margin: 0 30px 60px 30px;
    text-align: center;
}

.primary-background-section-header {
    color: white !important;
    margin-top: 40px;
}

.section-padding {
    padding: 0 8%;
}

.section-header-sub-page-spacer {
    padding: 0 8%;
    padding-top: 50px;
}

@media (min-width: 992px) {
    .bgc-section {
        background-color: var(--azi-background);
    }

    h3 {
        max-width: 50%;
    }

    .section-spacer {
        padding-top: 8%; //10%
        margin-top: 0%;
        background-color: var(--azi-background);
        z-index: 2;
        position: initial;
    }

    .sction-prim-white-spacer {
        padding-top: 6%;
        margin-top: 0%;
        background-color: var(--azi-background);
        z-index: 2;
        position: initial;
    }

    .section-header {
        font-size: 40px;
        font-weight: 600;
        color: var(--azi-primary);
        max-width: 100%;
        margin: 0 0px 90px 0px;
        text-align: left;
    }

    .primary-background-section-header {
        color: white !important;
        margin: 40px 0px 50px 0px !important;
    }

    .section-padding {
        padding: 0 12%;
    }

    .section-header-sub-page-spacer {
        padding: 0 12%;
        padding-top: 80px;
    }
}

.text-gradient {
    background: var(--azi-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-reverse {
    background: var(--azi-gradient-reverse);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.highlight-text-light{
    color: var(--azi-highlight-text-light)
}

.grecaptcha-badge{
    visibility: hidden;
}
