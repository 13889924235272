.spacer-prim-section {
    padding-top: 30%;
    background-color: #fff;
    position: relative;
    z-index: 2;
}

.azi-start-section {
    overflow: hidden;
    background: var(--azi-background);
    position: relative;
    z-index: 2;
    padding-top: 40px;
    h3 {
        max-width: unset;
    }
    .azi-start-header {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: white;
        margin: 0px;
        margin-block-start: 0.5em;
        margin-block-end: unset;
    }

    .azi-start-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        .azi-start-elements-container {
            max-width: 70%;
            .azi-start-element {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 94px 0;
                .azi-start-element-counter {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 65px;
                    height: 65px;
                    background-color: white;
                    color: var(--azi-highlight-text);
                    font-size: 38px;
                    border-radius: 50%;
                    box-shadow: 0 0px 7px 0px lightgray;
                }
                .azi-start-element-text {
                    color: black;
                    margin-top: 28px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }
        .azi-start-text {
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 40px;
        }
        .azi-start-button {
            width: 124px;
            display: none;
        }
        .azi-start-button-mobile {
            display: block;
            width: 124px;
        }
        .alert-collapse {
            margin-top: 30px;
            .alert-container {
                border: 2px solid var(--azi-primary) !important;
                border-radius: 20px;
                .alert-title {
                    font-weight: 600;
                    font-size: 15px;
                }
                .highlight-text {
                    color: var(--azi-highlight-text-light);
                }
            }
        }
        .alert-button-container {
            margin-top: 15px;
            display: flex;
            justify-content: flex-end;
            .azi-button {
                width: 20%;
                display: flex;
                justify-content: center !important;
                align-items: center !important;
            }
        }
    }
}

@media (min-width: 760px) {
    .spacer-prim-section {
        display: none;
    }

    .azi-start-section {
        margin: 0 0 0 0;

        .azi-start-container {
            .azi-start-elements-container {
                max-width: 90%;
                display: flex;
            }
        }
    }
}

@media (min-width: 992px) {
    .azi-start-section {
        padding-top: 40px;
        .azi-start-container {
            .azi-start-element{
                width: calc(100%/3);
            }
            .azi-start-button {
                width: 124px;
                display: block;
                transition: 0.3s !important;
            }
            .azi-start-elements-container {
                max-width: 80%;
                column-gap: 10%;
            }
            .azi-start-button-mobile {
                display: none;
            }
        }
    }
}
