.azi-gallery-section {
    background-color: var(--azi-background);
    position: relative;
    z-index: 2;
    .sub-header {
        text-align: center;
        font-size: 16px;
        color: var(--azi-primary);
        .highlight-text {
            color: #41b0ff;
        }
    }
    .azi-gallery-container {
        margin: 0px 20px 40px 20px;
        display: flex;
        align-items: center;
        picture {
            img {
                margin-top: 40px;
                width: 100%;
            }
        }
        picture:nth-of-type(2) {
            img {
                display: none;
            }
        }
    }
    .button-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        margin: 0px 0px;
        .button-text {
            text-align: center;
            color: var(--azi-primary);
        }
    }
}

.close-button-container {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
}

.gallery-header {
    text-align: center;
    width: 80%;
    margin: auto;
    color: var(--azi-primary);
    font-size: 22px;
    padding: 0 0 30px 0;
    .highlight-text {
        color: #41b0ff;
    }
}
.gallery-modal-container{
    width: 95%;;
    .gallery-image {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 0px 24px -11px rgba(66, 68, 90, 1);
    }
}


.image-element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
    .image-description-container {
        width: 95%;
        .image-title {
            color: var(--azi-primary);
            font-size: 20px;
            text-align: center;
        }
        .image-description {
            margin: 20px 0 20px 0;
            max-width: 100%;
            text-align: center;
            color: var(--azi-primary);
        }
    }
}

.dialog-container {
    div:nth-of-type(3) {
        div:nth-of-type(1) {
            border-radius: 30px;
        }
    }
}

.dialog-background {
    background-color: var(--azi-background);
}

@media (min-width: 760px) {
    .image-element-container {
        .image-description-container {
            .image-title {
                text-align: left;
            }
            .image-description {
                max-width: 70%;
                text-align: left;
            }
        }
    }
    .azi-gallery-section {
        .azi-gallery-container {
            margin: 0px 100px 40px 100px;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            justify-content: center;
            align-items: center;
            picture {
                width: 450px;
                img {
                    width: 100%;
                }
            }

            picture:nth-of-type(1) {
                margin-top: 40px;
            }
            picture:nth-of-type(2) {
                img {
                    display: none;
                }
            }
        }
        .button-container {
            align-items: center;
            margin: 0px 80px;
        }
    }
}

@media (min-width: 992px) {
    .azi-gallery-section {
        .sub-header {
            text-align: left;
            max-width: 50%;
        }
        .azi-gallery-container {
            margin: 30px 100px 40px 100px;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            picture {
                width: 45%;
                img {
                    width: 100%;
                }
            }

            picture:nth-of-type(2) {
                img {
                    display: block;
                }
            }
        }
        .button-container {
            align-items: flex-end;
            margin: 0px 80px;
        }
    }
}
