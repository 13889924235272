.bgc-container {
    background-color: var(--azi-background);
    position: relative;
    z-index: 2;
}

.azi-objectives-header {
    text-align: center;
}

.azi-objectives-section {
    position: relative;
    background-color: var(--azi-background);
    z-index: 2;
    padding-bottom: 25px;
    .azi-objectives-container {
        z-index: 2;
        display: flex;
        background-color: white;
        flex-direction: column;
        border-radius: 15px;
        box-shadow: var(--azi-element-shadow);
        .azi-objectives-element {
            z-index: 2;
            padding: 20px;
            color: var(--azi-primary);
            border-bottom: 1px solid lightgrey;

            &:nth-of-type(1) {
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
            }
            &:nth-of-type(3) {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
            }

            .azi-objectives-element-header {
                font-size: 28px;
                font-weight: 600;
                margin-top: 8px;
                text-align: center;
            }
            .azi-objectives-element-description {
                font-size: 14px;
                margin-top: 8px;
                text-align: center;
            }
            .azi-objectives-element-image {
                display: flex;
                justify-content: center;
                picture {
                    width: 120px;
                    height: 120px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .azi-objectives-element-button {
                margin: 16px auto 0px auto;
                width: 111px;
            }
        }
        .azi-objectives-element-selected {
            transition: 0.5s;
            background-color: var(--azi-primary);
            color: white;
            .azi-button-primary {
                cursor: auto;
                color: var(--azi-primary);
                &:hover {
                    background-color: var(--azi-primary);
                }
            }
        }
    }
    .azi-objectives-description-container {
        z-index: -1;
        position: relative;
        background: var(--azi-primary);
        padding: 20px;
        color: white;
        border-radius: 20px;
        font-weight: 600;
        margin: 30px 0 0 0;
        .azi-objectives-description-element {
            padding: 0 0 15px 0;
            img {
                margin: 0 10px 0 0;
            }
        }
    }
    .mobile {
        background-color: white !important;
        color: var(--azi-primary) !important;
    }
    .unmobile {
        display: none;
    }
    .unmobile-hide {
        display: none;
    }
}

@media (min-width: 992px) {
    .azi-objectives-section {
        .azi-objectives-container {
            flex-direction: row;
            .azi-objectives-element {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-bottom: none;
                width: calc(100% / 3);
                border-right: 1px solid lightgrey;
                &:nth-of-type(1) {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    border-top-right-radius: 0px;
                }
                &:nth-of-type(3) {
                    border-right: none;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    border-bottom-left-radius: 0px;
                }
                .azi-objectives-element-image {
                    display: flex;
                    justify-content: center;
                    margin-top: -100px;
                    picture {
                        width: 150px;
                        height: 120px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .azi-objectives-element-button {
                    margin: 16px auto 0px auto;
                    width: 111px;
                }
            }
        }
        .azi-objectives-description-container {
            z-index: -1;
            background: var(--azi-primary);
            padding: 25px 20px;
            color: white;
            border-radius: 20px;
            font-weight: 600;
            .azi-objectives-description-element {
                padding: 0 0 15px 0;
                img {
                    margin: 0 10px 0 0;
                }
            }
        }
        .unmobile {
            display: block;
        }
        .unmobile-hide {
            display: none;
        }
        .mobile {
            display: none;
        }
    }
}
