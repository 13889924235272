.azi-contact-section {
    overflow: hidden;
    background-color: var(--azi-primary);
    z-index: 2;
    position: relative;
    .azi-contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        .azi-contact-container-photo {
            display: none;
        }
    }
    .azi-contact-description {
        text-align: center;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        color: white;
        margin: 0px 25px;
    }
    .azi-contact-form-container {
        .azi-contact-form {
            margin: 37px auto 57px auto;
            width: 80%;
            .alert-collapse {
                margin-top: 30px;
                .alert-container {
                    border: 2px solid white !important;
                    border-radius: 20px;
                    .alert-title {
                        font-weight: 600;
                        font-size: 15px;
                    }
                    .highlight-text {
                        color: var(--azi-highlight-text-light);
                    }
                }
            }
            .alert-button-container {
                margin-top: 15px;
                display: flex;
                justify-content: flex-end;
                .azi-button {
                    width: 20%;
                    display: flex;
                    justify-content: center !important;
                    align-items: center !important;
                }
            }
            .azi-contact-form-elements {
                display: flex;
                flex-direction: column;
                .input-container {
                    display: flex;
                    flex-direction: column;
                }
                div {
                    input,
                    textarea {
                        max-height: 400px;
                        resize: none;

                        background-color: white;
                        border: none;
                        color: var(--azi-primary);
                        outline: none;
                        &::placeholder {
                            color: lightgrey;
                        }
                        &:focus {
                            border-color: white;
                        }
                        padding: 8px 24px;
                        font-size: 14px;
                        border-radius: 20px;
                        margin-bottom: 28px;
                        font-family: "Inter", sans-serif;
                    }
                    textarea {
                        min-height: 182px;
                    }
                }
                button {
                    margin: 0 0 0 auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    padding: 11.5px 15px;
                    border-radius: 52px;
                    border: none;
                    font-weight: 600;
                    font-size: 10px;
                    color: var(--azi-primary);
                    background: white;
                    width: 104px;
                    height: 34px;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    outline: none;
                    transition: transform 0.125s;
                    transition: background-color 0.3s;
                    &:hover {
                        background-color: var(--azi-white-button-active);
                    }
                    &:active {
                        transform: scale(0.9, 0.9);
                    }
                    &.loading {
                        animation: button-loading 0.5s forwards;
                    }
                    &.ready {
                        text-indent: 0;
                        color: transparent;
                        background-color: var(--azi-primary);
                        animation: button-ready 0.333s forwards;
                    }
                    &.fail {
                        text-indent: 0;
                        color: transparent;
                        background-color: var(--azi-primary);
                        animation: button-fail 0.333s forwards;
                    }
                    &.ready:before {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: auto;
                        margin: 0;
                        width: auto;
                        height: auto;
                        border-radius: 0;
                        background-color: transparent;
                        border-color: #8cce1e;
                        color: #8cce1e;
                        content: "✔";
                        opacity: 0;
                        z-index: 2;
                        animation: button-ready-label 0.5s forwards 0.275s;
                    }
                    &.ready:after {
                        opacity: 1;
                        animation: button-dot-outro 0.333s;
                    }
                    &.fail:before {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: auto;
                        margin: 0;
                        width: auto;
                        height: auto;
                        border-radius: 0;
                        background-color: transparent;
                        border-color: #ce1e1e;
                        color: #ce1e1e;
                        content: "❌";
                        opacity: 0;
                        z-index: 2;
                        animation: button-ready-label 0.5s forwards 0.275s;
                    }

                    &.fail:after {
                        opacity: 1;
                        animation: button-dot-outro 0.333s;
                    }
                }
                .azi-button {
                    margin: 0 0 0 auto;
                    width: 20%;
                }
            }
        }
    }
    .azi-contact-elments-container {
        width: 80%;
        margin: 37px auto 40px auto;
    }
    .azi-contact-element {
        margin-bottom: 24px;
        padding: 16px 20px;
        background-color: white;
        color: var(--azi-primary);
        box-shadow: 0px 4px 15px rgba(8, 58, 94, 0.4);
        border-radius: 14px;
        display: flex;
        .contact-element-photo {
            img {
                &:hover {
                    animation-name: shake;
                    animation-duration: 0.7s;
                    animation-iteration-count: infinite;
                }
                height: 100px;
            }
            margin-right: 14px;
        }
        .contact-element-description {
            display: flex;
            flex-direction: column;
            max-width: 95%;
            overflow: hidden;
            font-size: 12px;
            font-weight: 600;
            row-gap: 6px;
            .description-element-name {
                font-size: 18px;
                margin-bottom: 6px;
            }
            .description-element {
                white-space: pre-wrap; /* CSS3 */
                white-space: -moz-pre-wrap; /* Firefox */
                white-space: -pre-wrap; /* Opera <7 */
                white-space: -o-pre-wrap; /* Opera 7 */
                word-wrap: break-word;
                display: flex;
                align-items: flex-start;
                column-gap: 6px;
                div {
                    overflow: hidden;
                }
                &:nth-of-type(1) {
                    font-size: 10px;
                    margin-bottom: 8px;
                }
                &:nth-of-type(2) div {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .azi-contact-section {
        .azi-contact-container {
            margin: 0 5%;
            align-items: unset;
            .azi-contact-box-one-container {
                display: flex;
                flex-direction: column;
            }
            div:nth-of-type(1) {
                display: flex;
                justify-content: space-between;
                .azi-contact-container-photo {
                    display: flex;
                    align-items: center;
                    width: 60%;
                    img {
                        width: 40%;
                        min-width: 200px;
                        margin-top: -100px;
                    }
                }
                .azi-contact-form-container {
                    width: 40%;
                    display: flex;
                    flex-direction: column;
                    margin: 0px 0 0 0;
                    .azi-contact-form {
                        width: 100%;
                        .alert-collapse {
                            width:100%;
                            .alert-container{
                                width:100%;
                            }
                        }
                    }
                }
            }
        }
        .azi-contact-description {
            text-align: left;
            margin: 0px 0px;
        }
        .azi-contact-elments-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            column-gap: 31px;
            margin-top: 33px;
        }
        .azi-contact-element {
            padding: 16px 12px 16px 20px;
        }
    }
}
@keyframes button-loading {
    0% {
    }
    20% {
        color: transparent;
        transform: scale(1, 1);
    }
    40% {
        border-color: var(--azi-primary);
        background-color: transparent;
        transform: scale(1, 1);
    }
    60% {
        transform: scale(0.7, 0.7);
        width: 1.5rem;
        text-indent: -0.6125rem;
        color: transparent;
        border-color: var(--azi-primary);
        background-color: var(--azi-primary);
    }
    80% {
        transform: scale(1, 1);
    }
    100% {
        width: 2.5rem;
        background-color: var(--azi-primary);
        border-color: var(--azi-primary);
        color: transparent;
    }
}

@keyframes button-dot-intro {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 1;
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes button-dot-pulse {
    0% {
        border: none;
        opacity: 1;
        transform: scale(0.65, 0.65);
    }
    15% {
        border: none;
        transform: translate(0.75, 0.75);
    }
    45% {
        border: none;
        transform: translate(0.65, 0.65);
    }
    55% {
        border: none;
        transform: translate(0.85, 0.85);
    }
    85% {
        border: none;
        transform: translate(0.75, 0.75);
    }
    100% {
        border: none;
        opacity: 1;
        transform: translate(0.65, 0.65);
    }
}

@keyframes button-ready {
    0% {
    }
    10% {
        background-color: var(--azi-primary);
        border-color: #8cce1e;
    }
    70% {
        background-color: #fff;
        transform: scale(1.1, 1.1);
    }
    100% {
        font-weight: bold;
        border: 2px solid #8cce1e;
        background-color: #fff;
    }
}

@keyframes button-dot-outro {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(1, 1);
    }
}

@keyframes button-ready-label {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes shake {
    0% {
        transform: translateY(0px) rotateX(0deg);
        //transform-origin: bottom;
    }
    50% {
        transform: translateY(-5px) rotateX(15deg);
        //transform-origin: bottom;
    }
    50% {
        transform: translateY(0px) rotateX(0deg);
        //transform-origin: bottom;
    }
    75% {
        transform: translateY(5px) rotateX(-15deg);
        //transform-origin: bottom;
    }
    100% {
        transform: translateY(0px) rotateX(0deg);
        //transform-origin: bottom;
    }
}

.disp-none{
    display: none;
}

@keyframes button-fail {
    0% {
    }

    10% {
        background-color: var(--azi-primary);
        border-color: #ce1e1e;
    }

    70% {
        background-color: #fff;
        transform: scale(1.1, 1.1);
    }

    100% {
        font-weight: bold;
        border: 2px solid #ce1e1e;
        background-color: #fff;
    }
}
