.language-snackbar-constainer{
    position: fixed;
    z-index: 3;
    bottom: 20px;
    left: 20px;
    max-width: 300px;
    max-height: 500px;

    .alert-collapse {
        margin-top: 30px;
        .alert-container {
            border: 1.5px solid var(--azi-primary) !important;
            border-radius: 20px;
            .alert-title {
                font-weight: 600;
                font-size: 15px;
                margin-bottom: 16px;
            }
            .alert-text{
                margin: 0 16px;
                font-weight: normal;
                font-size: 11px;
            }
            .highlight-text {
                color: var(--azi-highlight-text-light);
            }

            .alert-button-container{
                display: flex;
                .azi-button{
                    color: var(--azi-primary);
                    background-color: transparent;
                    border: 1.5px solid var(--azi-primary);
                    margin-left: auto;
                    max-height: 50px;
                    padding: 8.5px 15px;
    
                    &:hover{
                        color: white;
                        background-color: var(--azi-primary);
                    }
                }
            }
       
        }
    }
}