.header-subpage{
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--azi-primary);

    .header-subpage-text{
        font-weight: 600;
        font-size: 55px;
        line-height: 87px;
        position: fixed;
        color: white;
        text-align: center;
        margin: 0 auto

    }

}

@media(min-width: 992px){
    .header-subpage{
        .header-subpage-text{
            position: initial;
            font-size: 72px;
        }
    }
}