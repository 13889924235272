header {
    width: 100%;
    background-color: var(--azi-primary);
}

.vector-img {
    background-color: var(--azi-primary);
    width: 100%;
    height: 313.19px;
    position: relative;
    z-index: 0;
    .cube-image {
        display: none;
    }
}
.vector-svg {
    display: none;
}

.header-text-container {
    position: fixed;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 23px 60px 23px;
    text-align: center;
    p {
        font-size: 58px;
        margin: 0;
        transition: .3s;
        cursor: initial;

        &:hover{
            transform: translateY(-10px);
        }
    }
    h1 {
        font-size: 20px;
    }
}

.header-screens-container {
    padding: 126px 29px 0 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;
    img {
        width: 375px;
        height: 173px;
    }
}

.header-counter-container-mobile {
    position: relative;
    box-shadow: 0px -42px 65px -35px rgb(44 44 44);
    z-index: 2;
    border-radius: 45px 45px 0 0;
    background-color: #fafbfc;
    display: flex;
    flex-direction: column;
    color: var(--azi-primary);
    align-items: center;
    padding: 50px 0 0 0;
    margin: 50px 0 0 0;
    .header-counter-element-mobile {
        &:nth-of-type(3) {
            padding: 0 0 0 0;
        }
        text-align: center;
        padding: 0 0 62px 0;
        font-weight: 600;
        div:nth-of-type(1) {
            font-size: 38px;
            font-weight: 600;
        }
        div:nth-of-type(2) {
            font-size: 18px;
        }
    }
}

.header-counter-container {
    display: none;
}

@media (min-width: 992px) {
    header {
        background-color: #fafbfc;
    }

    .vector-img {
        height: 100%;
        justify-content: space-between;
        .cube-image {
            padding: 50px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 3%;
            right: 10%;
            img {
                width: 539px;
            }
        }
    }
    .header-counter-container-mobile {
        display: none;
    }
    .header-counter-container {
        display: flex;
        flex-direction: row;
        color: var(--azi-primary);
        justify-content: center;
        align-items: center;
        column-gap: 100px;
        padding: 50px 70px 0 0;
        margin-left: -100px;
        .header-counter-element {
            width:150px;
            text-align: center;
            padding: 0 0 62px 0;
            font-weight: 600;
            div:nth-of-type(1) {
                font-size: 38px;
                font-weight: 600;
                padding: 0 0 10px 0;
            }
            div:nth-of-type(2) {
                font-size: 18px;
                white-space: nowrap;
            }
        }
    }
    .vector-svg {
        display: block;
        margin-top: -3px;
        width: 60%;
        height: 40vh;
    }
    .header-text-container {
        position: initial;
        align-items: flex-start;
        padding: 40px 0 3px 15%;
        text-align: left;
        width: 300px;
        p {
            font-size: 70px;
            margin: 15px 0;
        }
        h1 {
            font-size: 17px;
        }
    }

    .header-screens-container {
        position: absolute;
        top: 39px;
        right: 148px;
        padding: 0;

        img {
            border-radius: 22px;
            box-shadow: var(--azi-element-shadow);
            width: 100%;
            height: 185px;
            object-fit: cover;
            object-position: 50% 50%;
        }

        img:nth-of-type(1) {
            transform: translate(92px, 71px);
        }
        img:nth-of-type(2) {
            transform: translate(0, -43px);
        }
    }
}

@media (min-width: 1540px) {
    .vector-img {
        column-gap: 25%;
        .cube-image {
            top: 18%;
            right: 9%;
            img {
                width: 745px;
            }
        }
    }
    .know-more-button {
        font-size: 14px;
        padding: 12px 28px;
        margin-bottom: 3px;
    }
    .header-counter-container {
        column-gap: 150px;
        margin-left: -60px;
        padding: 50px 0 0 0;
    }
    .header-text-container {
        padding: 130px 0 0 15%;
        width: 440px;
        p {
            font-size: 100px;
            margin: 15px 0;
        }
        h1 {
            font-size: 25px;
        }
    }
    .header-screens-container {
        top: 76px;
        right: 265px;

        img {
            height: 320px;
        }
        img:nth-of-type(1) {
            transform: translate(92px, 158px);
        }
    }
}

// @property --product-count {
//     syntax: "<integer>";
//     initial-value: 53000;
//     inherits: false;
// }

// @property --manufacturer-count {
//     syntax: "<integer>";
//     initial-value: 2000;
//     inherits: false;
// }

// @property --attrib-count {
//     syntax: "<integer>";
//     initial-value: 200;
//     inherits: false;
// }

.product-count {
    // animation: product-counter 1s;
    // counter-reset: product-count var(--product-count);
    font: 800 40px system-ui;
    padding: 2rem;
}

// .product-count::after {
//     content: counter(product-count) "+";
// }

// @keyframes product-counter {
//     from {
//         --product-count: 0;
//     }
//     to {
//         --product-count: 53000;
//     }
// }

.manufacturer-count {
    // animation: manufacturer-counter 1s;
    // counter-reset: manufacturer-count var(--manufacturer-count);
    font: 800 40px system-ui;
    padding: 2rem;
}
// .manufacturer-count::after {
//     content: counter(manufacturer-count) "+";
// }

// @keyframes manufacturer-counter {
//     from {
//         --manufacturer-count: 0;
//     }
//     to {
//         --manufacturer-count: 2000;
//     }
// }

.attrib-count {
    // animation: attrib-counter 1s;
    // counter-reset: attrib-count var(--attrib-count);
    font: 800 40px system-ui;
    padding: 2rem;
}
// .attrib-count::after {
//     content: counter(attrib-count) "+";
// }

// @keyframes attrib-counter {
//     from {
//         --attrib-count: 0;
//     }
//     to {
//         --attrib-count: 3000;
//     }
// }



