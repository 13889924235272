.background {
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;
}

.scheme-description {
    display: none;
}
.azi-scheme-container {
    display: flex;
    justify-content: center;
    padding: 60px 0 0 0;
    picture {
        width: 80%;
    
        img {
            width: 100%;
            transition: 0.5s;
        }
        img:nth-of-type(1) {
            display: none;
        }
    }
}

@media (min-width: 768px) {
    .scheme-description {
        display: block;
        color: var(--azi-primary);
        width: 70%;
        text-align: center;
        margin: auto;
    }
    .azi-scheme-container {
        display: flex;
        justify-content: center;
        padding: 60px 0 0 0;
        picture {
            img {
                width: 80%;
            }
            img:nth-of-type(1) {
                display: block;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .azi-info-text {
        padding: 0;
        text-align: left;
        font-size: 14px;
        width: 50%;
        padding: 30px 0 20px 15%;
    }

    .scheme-description {
        display: block;
        width: 40%;
        padding: 0 0 0 15%;
        text-align: left;
        margin: 0;
    }

    .azi-scheme-container {
        picture {
            width: 70%;
            img {
                width: 100%;
            }
        }
    }
}

@media (min-width: 1540px) {
    .azi-info-text {
        width: 40%;
        font-size: 17px;
        padding: 20px 0 20px 15%;
    }

    .azi-scheme-container {
        picture {
            width: 65%;
            img {
                width: 100%;
            }
        }
    }
}
