footer {
    background-color: var(--azi-primary);
    position: relative;
    z-index: 2;
    color: white;
    padding: 46px 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-elements-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .address {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin-bottom: 27px;
            }
            p {
                margin: 0 0 8px 0;
                font-weight: 600;
                font-size: 13px;
                line-height: 12px;
            }
        }
        .services {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 46px 0;
            .services-header {
                margin-bottom: 22px;
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
            }
            p {
                margin: 0 0 8px 0;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
            }
        }
        .platform {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 23px 0;
            .platform-header {
                margin-bottom: 22px;
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
            }
            p,
            a {
                margin: 0;
                font-weight: 600;
                font-size: 13px;
                line-height: 12px;
                color: white;
                display: flex;
                align-items: center;
             
                height: 48px;
            }
        }
        .contact {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 23px 0;
            .contact-header {
                margin-bottom: 22px;
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
            }
            p {
                margin: 0 0 8px 0;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
            }
            .socials-container {
                margin: 23px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 18px;

                img {
                    width: 27px;
                    height: 27px;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: 0.3s;

                    &:hover {
                        transform: scale(1.2);
                    }
                }
                img:nth-of-type(1):hover {
                    box-shadow: 0px 0px 5px 2px #d6249f;
                }
                img:nth-of-type(2):hover {
                    box-shadow: 0px 0px 5px 2px #00f2ea;
                }
                img:nth-of-type(3):hover {
                    box-shadow: 0px 0px 5px 2px #1da1f2;
                }
                img:nth-of-type(4):hover {
                    box-shadow: 0px 0px 5px 2px #0077b5;
                }
                img:nth-of-type(5):hover {
                    box-shadow: 0px 0px 5px 2px #1778f2;
                }
            }
        }
    }
    .footer-photo {
        margin: 30px 0;
        background-color: white;
        border-radius: 50px;
        padding: 10px 30px;
        width: 80%;
        img {
            width: 100%;
        }
    }
    .footer-text {
        text-align: center;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
    }
}

@media (min-width: 992px) {
    footer {
        padding: 46px 12%;
        .footer-container {
            width: 100%;
        }
        .footer-elements-container {
            width: 100%;
            margin: 0 29px;
            column-gap: 9%;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            .address {
                align-items: flex-start;
                p {
                    font-weight: 500;
                    line-height: 15px;
                }
            }
            .services {
                align-items: flex-start;
                margin: 15px 0 46px 0;
                .services-header {
                    margin-bottom: 37px;
                }
                p {
                    text-align: left;
                    font-weight: 500;
                    line-height: 18px;
                }
            }
            .platform {
                align-items: flex-start;
                margin: 15px 0 46px 0;
                .platform-header {
                    margin-bottom: 37px;
                }
                p,
                a {
                    margin-bottom: 8px;
                    display: block;
                    height: unset;
                    font-weight: 500;
                    line-height: 18px;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .contact {
                align-items: flex-start;
                margin: 15px 0 46px 0;
                .contact-header {
                    margin-bottom: 37px;
                }
                p {
                    font-weight: 500;
                }
            }
        }
        .footer-photo {
            margin: 30px;
            display: flex;
            justify-content: center;
            width: 50%;
        }
    }
}
