.azi-partners-section {
    overflow: hidden;
    background-color: var(--azi-background);
    position: relative;
    z-index: 2;

    .azi-partners-header {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: var(--azi-primary);
        margin-bottom: 55px;
        max-width: 100%;
        margin-top: 0px;
    }
    .azi-partners-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .azi-partner-element-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .azi-partner-element {
            margin-bottom: 62px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .azi-partner-element-photo {
                display: flex;
                justify-content: center;
                .brave-logo {
                    width: 60%;
                }
                .gs1-logo{
                    width: 70%;
                }
            }
            .azi-partner-element-description {
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                color: var(--azi-primary);
                margin-top: 31px;
            }
        }
        .azi-partner-photo {
            margin-top: 50px;
        }

        .azi-partners-logo-container {
            display: flex;
            flex-direction: column;
            row-gap: 30px;

            div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                column-gap: 30px;

                img {
                    width: 80px;
                }
                img:nth-of-type(2) {
                    width: 110px;
                }
            }

            div:nth-of-type(2) {
                img:nth-of-type(2) {
                    width: 110px;
                }
            }
        }
    }
}

@media (min-width: 760px) {
    .azi-partners-section {
        .azi-partners-container {
            .azi-partner-element {
                max-width: 50%;
            }

            .azi-partners-logo-container {
                flex-direction: row;
                row-gap: 0;
                column-gap: 30px;
            }
        }
    }
}

@media (min-width: 992px) {
    .azi-partners-section {
        .azi-partners-header {
            text-align: left;
            margin-top: 0px;
            margin-left: 0px;
        }
        .azi-partners-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 50px 29px 0px 0px;
            .azi-partner-element-container {
                width: 100%;
                flex-direction: row;
                column-gap: 35px;
            }
            .azi-partner-element {
                margin-bottom: 80px;
                display: flex;
                flex-direction: row;
                align-items: center;
                .azi-partner-element-photo {
                    .brave-logo {
                        width: 100%;
                    }
                }
                .azi-partner-element-description {
                    text-align: left;
                    margin-top: 0;
                    margin-left: 14px;
                }
            }
            .azi-partners-logo-container {
                column-gap: 50px;
                div {
                    column-gap: 50px;
                    img {
                        width: 70px;
                    }
                    img:nth-of-type(2) {
                        width: 150px;
                    }
                }
                div:nth-of-type(2) {
                    img:nth-of-type(2) {
                        width: 150px;
                    }
                }
            }
        }
    }
}
