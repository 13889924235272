.azi-news-section {
    background-color: var(--azi-background);
    z-index: 2;
    position: relative;
    border-radius: 45px 45px 0 0;
    box-shadow: 0px -42px 65px -35px rgb(44 44 44);

    .azi-news-socials {
        font-size: 20px;
        color: var(--azi-primary);
        width: 80%;
        margin: 0 auto;
        text-align: center;
        .azi-news-socials-container {
            display: flex;
            justify-content: center;
            column-gap: 20px;

            img {
                width: 30px;
                height: 30px;

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    .azi-news-container {
        padding-bottom: 50px;
        background-color: var(--azi-background);
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 200px);
        gap: 20px;

        .azi-news-element {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            transition: 0.3s;
            position: relative;
            overflow: hidden;
            border-radius: 10px;

            img {
                transition: 0.5s;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .azi-inner-element {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                transition: 0.5s;
                background: linear-gradient(
                    180deg,
                    rgba(238, 238, 238, 0) 0%,
                    rgba(8, 58, 94, 1) 100%
                );
            }

            &:nth-of-type(1) {
                grid-column: 1 / span 1;
                grid-row: 1 / span 2;
            }
            &:nth-of-type(2) {
                grid-column: 1 / span 1;
                grid-row: 3 / span 2;
            }

            &:nth-of-type(3) {
                grid-column: 1 / span 1;
                grid-row: 5 / span 2;
            }

            &:hover {
                cursor: pointer;
                img {
                    transform: scale(1.25);
                }
                .azi-inner-element {
                    background: linear-gradient(
                        180deg,
                        rgba(238, 238, 238, 0) 0%,
                        #1179c4 100%
                    );
                }
                .azi-news-element-text {
                    .text {
                        text-decoration: underline;
                    }
                }
            }

            .azi-news-inner-element {
                position: absolute;
                top: 50%;
                height: 50%;
            }

            .azi-news-element-text {
                position: absolute;
                display: flex;
                flex-direction: column;
                margin: 0 30px;
                font-size: 20px;
                bottom: 30px;
                color: #fff;
                width: 87%;

                .text {
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                .date {
                    font-size: 12px;
                    color: rgb(240, 240, 240);
                }
            }
        }
    }
}

@media (min-width: 760px) {
    .azi-news-section {

        .azi-news-container {
            grid-template-columns: repeat(2, 1fr);
            // grid-template-rows: repeat(2, 200px);
            grid-template-rows: repeat(4, 200px);

            .azi-news-element {
                &:nth-of-type(1) {
                    grid-column: 1 / span 2;
                    // grid-column: 1 / span 4;
                    grid-row: 1 / span 2;
                }
                &:nth-of-type(2) {
                    grid-column: 3 / span 2;
                    grid-row: 1 / span 1;
                }

                &:nth-of-type(3) {
                    grid-column: 3 / span 2;
                    grid-row: 2 / span 1;
                }

                &:nth-of-type(4) {
                    grid-column: 1 / span 2;
                    grid-row: 3 / span 1;
                }

                &:nth-of-type(5) {
                    grid-column: 3 / span 2;
                    grid-row: 3 / span 1;
                }

                &:nth-of-type(6) {
                    grid-column: 1 / span 2;
                    grid-row: 4 / span 1;
                }
                &:nth-of-type(7) {
                    grid-column: 3 / span 2;
                    grid-row: 4 / span 1;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .azi-news-section {
        box-shadow: none;
        border-radius: 0;
        .azi-news-container {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, 200px);

            .azi-news-element {
                &:nth-of-type(1) {
                    grid-column: 1 / span 2;
                    // grid-column: 1 / span 6;
                    grid-row: 1 / span 2;
                }
                &:nth-of-type(2) {
                    grid-column: 3 / span 2;
                    grid-row: 1 / span 2;
                }

                &:nth-of-type(3) {
                    grid-column: 4 / span 3;
                    grid-row: 2 / span 1;
                }

                &:nth-of-type(4) {
                    grid-column: 1 / span 2;
                    grid-row: 3 / span 1;
                }

                &:nth-of-type(5) {
                    grid-column: 3 / span 2;
                    grid-row: 3 / span 1;
                }

                &:nth-of-type(6) {
                    grid-column: 5 / span 2;
                    grid-row: 3 / span 1;
                }
                &:nth-of-type(7) {
                    grid-column: 1 / span 2;
                    grid-row: 4 / span 1;
                }
                &:nth-of-type(8) {
                    grid-column: 3 / span 2;
                    grid-row: 4 / span 1;
                }
            }
        }
    }
}

@media (min-width: 1540px) {
    .azi-news-section {
        .azi-news-socials {
            font-size: 28px;
            .azi-news-socials-container {
                img {
                    width: 36px;
                    height: 36px;
                }
            }
        }
        .azi-news-container {
            margin: 50px 0 0 0;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(4, 200px);

            .azi-news-element {
                &:nth-of-type(1) {
                    grid-column: 1 / span 4;
                    // grid-column: 1 / span 8;
                    grid-row: 1 / span 3;
                }
                &:nth-of-type(2) {
                    grid-column: 5 / span 4;
                    grid-row: 1 / span 3;
                }

                &:nth-of-type(3) {
                    grid-column: 7 / span 2;
                    grid-row: 1 / span 2;
                }

                &:nth-of-type(4) {
                    grid-column: 5 / span 4;
                    grid-row: 3 / span 2;
                }

                &:nth-of-type(5) {
                    grid-column: 1 / span 2;
                    grid-row: 4 / span 1;
                }

                &:nth-of-type(6) {
                    grid-column: 3 / span 2;
                    grid-row: 4 / span 1;
                }
                &:nth-of-type(7) {
                    grid-column: 1 / span 2;
                    grid-row: 5 / span 1;
                }
                &:nth-of-type(8) {
                    grid-column: 3 / span 2;
                    grid-row: 5 / span 1;
                }
            }
        }
    }
}

